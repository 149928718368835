import classNames from "classnames"
import { useRouter } from "next/router"
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from "react"

import { IconClose } from "@icons/close"
import { IconSearch } from "@icons/search"
import { addSearchQuery, popSearchQuery, removeSearchQuery } from "@utils/search"

interface SearchInputProps {
  className?: string
  onSearch?: (queries: string[]) => void
  placeholder: string
}

export const AppSearchInput = ({ className, onSearch, placeholder }: SearchInputProps) => {
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)
  const queries = useMemo(
    () =>
      typeof router.query.q !== "string" || router.query.q.length === 0
        ? []
        : router.query.q.split(","),
    [router.query.q]
  )

  // search handler
  const handleSearch = useCallback(() => {
    const query = inputRef.current!.value
    if (!query.trim()) return

    // clear input field
    inputRef.current!.value = ""

    // update search terms
    const newSearchQueryParams = addSearchQuery(query)
    if (newSearchQueryParams) {
      onSearch?.(queries)
      router.push(...newSearchQueryParams)
    }
  }, [onSearch, queries, router])

  // pop search query handler
  const handlePopQuery = useCallback(() => {
    // do nothing if value is not empty
    const query = inputRef.current!.value
    if (query) return

    // remove most recent search term
    const newSearchQueryParams = popSearchQuery()
    if (newSearchQueryParams) router.push(...newSearchQueryParams)
  }, [router])

  // allow user to press the "Enter" key to search
  useEffect(() => {
    if (inputRef.current) {
      const refCurrent = inputRef.current
      const handler = (e: KeyboardEvent) => {
        if (e.key === "Enter") handleSearch()
        if (e.key === "Backspace") handlePopQuery()
      }
      refCurrent.addEventListener("keyup", handler)
      return () => refCurrent.removeEventListener("keyup", handler)
    }
  }, [handleSearch, handlePopQuery])

  // sync search keywords
  const handleRemoveQuery = (e: SyntheticEvent) => {
    const query = e.currentTarget.getAttribute("data-query")
    if (query) {
      const newSearchQueryParams = removeSearchQuery(query)
      if (newSearchQueryParams) router.push(...newSearchQueryParams)
    }
  }
  const queryBlocks = queries.map(query => (
    <button
      key={query}
      className="group flex items-center space-x-1 rounded-[60px] bg-[#C4C4C4] py-1 pl-3 pr-2 text-sm text-black"
      onClick={handleRemoveQuery}
      data-query={query}
    >
      <span className="max-w-1/2 block text-left line-clamp-1">{query}</span>
      <IconClose className="shrink-0 translate-y-px stroke-black transition group-hover:stroke-black/60" />
    </button>
  ))

  return (
    <div
      className={classNames(
        "relative flex h-[46px] items-center space-x-2 rounded-[76px] border border-white pl-3 pr-4",
        className
      )}
    >
      {queryBlocks}
      {queries.length < 3 && (
        <input
          ref={inputRef}
          type="text"
          className={classNames(
            "w-full min-w-[10%] flex-1 border-none bg-transparent pl-1 pr-0 text-sm text-white placeholder:text-white/50 focus:outline-none focus:ring-0 focus:ring-offset-0",
            queryBlocks.length === 0 && "sm:pl-4"
          )}
          placeholder={queryBlocks.length === 0 ? placeholder : ""}
        />
      )}
      <IconSearch
        className={classNames("shrink-0 cursor-pointer stroke-white hover:opacity-80")}
        onClick={handleSearch}
      />
    </div>
  )
}

import { IconProps } from "./props"

export const IconClose = ({ className, style, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      width="8"
      height="8"
      viewBox="0 0 8 8"
      style={style}
      onClick={onClick}
    >
      <path d="M6.717 0.354L0.353 6.718"></path>
      <path d="M6.717 6.718L0.353 0.354"></path>
    </svg>
  )
}

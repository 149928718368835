import classNames from "classnames"

import { IconSearch } from "@icons/search"

interface SearchInputProps {
  className?: string
  value: string
  placeholder: string
  onChange: (value: string) => void
}

export const SearchInput = ({ className, value, placeholder, onChange }: SearchInputProps) => {
  return (
    <div className={classNames("relative", className)}>
      <input
        type="text"
        className="search-input"
        value={value}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
      />
      <IconSearch className="absolute-center-y right-4 cursor-pointer stroke-white hover:opacity-80" />
    </div>
  )
}

import { useId } from "react"

import { useGlobalFields } from "@contexts/acf-global"
import { FormError, useNewsletterForm } from "@hooks/forms"
import { IconFormCheck } from "@icons/form-check"
import { IconLoading } from "@icons/loading"
import { IconNewsletterArrow } from "@icons/newsletter-arrow"

interface NewsletterSMProps {
  formName: string
  className?: string
}

export const NewsletterSM = ({ formName, className }: NewsletterSMProps) => {
  const formId = useId()
  const { refEmail, refTC, submit, hasSubmitted, loading, error } = useNewsletterForm(formName)
  const {
    formMessages,
    footer: { newsletterTitle, newsletterPlaceholder, newsletterTc, newsletterThankYou },
  } = useGlobalFields()

  return (
    <div className={className}>
      {/* show form fields if not yet done  */}
      {!hasSubmitted && (
        <>
          <label
            htmlFor={`${formId}:email`}
            className="title-newsletter-sm"
            dangerouslySetInnerHTML={{ __html: newsletterTitle }}
          />
          <div className="relative mt-6">
            <input
              ref={refEmail}
              type="text"
              className="footer-newsletter-input"
              id={`${formId}:email`}
              name={`${formId}:email`}
              placeholder={newsletterPlaceholder}
            />
            {loading ? (
              <IconLoading className="absolute-center-y right-1 w-10 fill-white" />
            ) : (
              <IconNewsletterArrow
                className="absolute-center-y right-4 cursor-pointer fill-white hover:opacity-80"
                onClick={submit}
              />
            )}
          </div>
          <div className="mx-4 mt-4 flex items-start space-x-2">
            <input
              ref={refTC}
              type="checkbox"
              className="form-chk inverted mt-1"
              name={`${formId}:gdpr`}
              id={`${formId}:gdpr`}
              disabled={loading}
              onChange={e => e.target.checked && refEmail.current?.focus()}
            />
            <label
              className="select-none"
              htmlFor={`${formId}:gdpr`}
              dangerouslySetInnerHTML={{ __html: newsletterTc }}
            />
          </div>
        </>
      )}

      {/* show thank you message if form was submitted successfully */}
      {hasSubmitted && (
        <>
          <div className="title-newsletter-sm max-w-[324px]">{newsletterThankYou}</div>
          <IconFormCheck className="mt-6 h-32 w-32 lg:h-36 lg:w-36" stroke="white" />
        </>
      )}

      {/* show error messages (if any) */}
      {error !== null && (
        <div className="mt-2 ml-5 h-4 text-sm text-red-400/80">
          {error === FormError.InvalidEmail
            ? formMessages.invalidEmail
            : error === FormError.UncheckedTC
            ? formMessages.uncheckedTc
            : formMessages.genericError}
        </div>
      )}
    </div>
  )
}

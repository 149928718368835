import React, { ReactNode } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import invariant from "@utils/invariant"

interface RecaptchaProviderProps {
  children: ReactNode
}

export const RecaptchaProvider = ({ children }: RecaptchaProviderProps) => {
  invariant(
    process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    "NEXT_PUBLIC_RECAPTCHA_SITE_KEY is undefined"
  )
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      scriptProps={{
        defer: true,
        appendTo: "body",
        nonce: undefined,
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}

import { useRouter } from "next/router"
import { useCallback } from "react"

import { addSearchQuery } from "@utils/search"

interface SearchSuggestionProps {
  children: string
}

export const SearchSuggestion = ({ children }: SearchSuggestionProps) => {
  const router = useRouter()
  const handleSearch = useCallback(() => {
    if (!children.trim()) return

    // go to search page (or not)
    const newSearchQueryParams = addSearchQuery(children)
    if (newSearchQueryParams) router.push(...newSearchQueryParams)
  }, [children, router])

  return (
    <button
      key={children}
      className="app-btn search-suggestion mx-[3px] mt-3 border-none bg-white bg-opacity-60 text-black"
      onClick={handleSearch}
    >
      {children}
    </button>
  )
}

import classNames from "classnames"
import { motion, useAnimation, Variants } from "framer-motion"
import Image from "next/legacy/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useId, useState } from "react"

import confEmployees from "@config/pages/medarbetare.json"
import confPosts from "@config/pages/posts.json"
import confServices from "@config/pages/services.json"
import { useGlobalFields } from "@contexts/acf-global"
import { SPEED, useCirculartAnimation } from "@hooks/animations"
import { FormError, useNewsletterForm } from "@hooks/forms"
import { useResponsiveLG } from "@hooks/shared"
import { IconDocument } from "@icons/document"
import { IconFormCheck } from "@icons/form-check"
import { IconLoading } from "@icons/loading"
import { IconNewsletterArrow } from "@icons/newsletter-arrow"
import { WPMedia } from "@models/common"
import { WPPostTypePlural } from "@models/posts"
import { getAppLocale } from "@utils/locale"
import { formatPostCategory, formatPostDate } from "@utils/posts"

const baseCardVariants: Variants = {
  enter: { y: 0, x: 0, opacity: 1 },
}

export const PostCardSM = ({
  postType,
  slug,
  title,
}: {
  postType: WPPostTypePlural
  slug: string
  title: string
  className?: string
}) => {
  const { locale } = useRouter()
  const { etc } = useGlobalFields()
  const [hover, setHover] = useState(false)
  const animation = useAnimation()
  const isResponsiveLG = useResponsiveLG()

  useCirculartAnimation(hover, animation, 4, SPEED.FAST)

  return (
    <motion.div
      className="cursor-pointer"
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <Link key={postType + slug} href={buildPostSlug(postType, slug, locale)}>
        {/* <motion.a> won't allow open in new tab option */}
        <motion.div
          className="post-card-sm flex h-full min-h-[143px] cursor-pointer flex-col justify-between rounded-2xl bg-[#D1D6D9] px-6 pt-4 pb-6 text-black transition"
          variants={baseCardVariants}
          animate={animation}
        >
          <h3 className="app-h3 mb-8">{title}</h3>
          <div className="flex-1"></div>
          <div className="flex items-center space-x-2">
            <span className="text-xs">{etc.readMoreLabel}</span>
            <motion.div
              initial={{ opacity: 0, x: "-75%" }}
              animate={isResponsiveLG ? (hover ? { opacity: 1, x: 0 } : {}) : { opacity: 1, x: 0 }}
              transition={{ ease: "easeIn", duration: 0.31 }}
              className="pt-[2px]"
            >
              <IconNewsletterArrow className="w-4 overflow-hidden" />
            </motion.div>
          </div>
        </motion.div>
      </Link>
    </motion.div>
  )
}

export const PostCard = ({
  id,
  postType,
  slug,
  date,
  title,
  categories,
  categoryLabel,
  externalLink,
  className,
}: {
  id: string
  postType: WPPostTypePlural
  slug: string
  date: string
  title: string
  categories: string[] | null
  categoryLabel?: string
  externalLink?: string
  className?: string
}) => {
  const {
    etc,
    post: { postCategoryLabel },
  } = useGlobalFields()
  const { locale } = useRouter()
  const [hover, setHover] = useState(false)
  const animation = useAnimation()
  const isResponsiveLG = useResponsiveLG()

  useCirculartAnimation(hover, animation, 2, SPEED.FAST)

  const linkAttr = externalLink
    ? {
        href: externalLink,
        target: "_blank",
        rel: "noreferrer",
      }
    : {}

  const linkContent = (
    <a {...linkAttr}>
      {/* <motion.a> won't allow open in new tab option */}
      <motion.div
        className={classNames(
          "flex h-full min-h-[171px] flex-col rounded-2xl bg-[#D1D6D9] px-[42px] py-[22px] text-black shadow lg:min-h-[238px] lg:px-[18px]",
          className
        )}
        variants={baseCardVariants}
        animate={animation}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <IconDocument />
            <span className="text-xs">
              {categoryLabel ? categoryLabel : formatPostCategory(categories, postCategoryLabel)}
            </span>
          </div>
          <span className="text-xs">{formatPostDate(date)}</span>
        </div>
        <h3 className="title-article mt-3 mb-8">{title}</h3>

        <div className="flex-1"></div>
        <div className="flex items-center space-x-2">
          <span className="text-xs">{etc.readMoreLabel}</span>
          <motion.div
            initial={{ opacity: 0, x: "-75%" }}
            animate={isResponsiveLG ? (hover ? { opacity: 1, x: 0 } : {}) : { opacity: 1, x: 0 }}
            transition={{ ease: "easeIn", duration: 0.33 }}
            className="pt-0.5"
          >
            <IconNewsletterArrow className="w-4 overflow-hidden" />
          </motion.div>
        </div>
      </motion.div>
    </a>
  )

  return (
    <motion.div
      className="h-full cursor-pointer"
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      {externalLink ? (
        linkContent
      ) : (
        <Link
          href={buildPostSlug(postType, postType === "posts" ? `${slug}-${id}` : slug, locale)}
          prefetch={false}
          legacyBehavior
        >
          {linkContent}
        </Link>
      )}
    </motion.div>
  )
}

export const PostCardLG = ({
  id,
  postType,
  image,
  slug,
  date,
  title,
  categoryLabel,
  categories,
}: {
  id: string
  postType: WPPostTypePlural
  image?: WPMedia
  slug: string
  date: string
  title: string
  categoryLabel?: string
  categories: string[]
}) => {
  const {
    etc,
    post: { postCategoryLabel },
  } = useGlobalFields()
  const { locale } = useRouter()
  const [hover, setHover] = useState(false)
  const animation = useAnimation()
  const isResponsiveLG = useResponsiveLG()

  useCirculartAnimation(hover, animation, 2, SPEED.FAST)
  return (
    <motion.div
      className="h-full cursor-pointer"
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <Link href={buildPostSlug(postType, `${slug}-${id}`, locale)}>
        {/* <motion.a> won't allow open in new tab option */}

        <motion.div
          className="post-card-lg rounded-article-card flex h-full cursor-pointer flex-col overflow-hidden rounded-[15px] bg-[#D1D6D9] text-black"
          variants={baseCardVariants}
          animate={animation}
        >
          <div
            className={classNames("aspect-w-16 aspect-h-9", {
              "bg-white/20": !image,
            })}
          >
            {image && (
              <Image src={image.mediaItemUrl} alt={image.altText} layout="fill" objectFit="cover" />
            )}
          </div>
          <div className="flex flex-1 flex-col px-10 pt-4 pb-6 lg:px-12">
            <div className="flex justify-between">
              <div className="flex items-center space-x-2">
                <IconDocument />
                <span>
                  {categoryLabel
                    ? categoryLabel
                    : formatPostCategory(categories, postCategoryLabel)}
                </span>
              </div>
              <div className="mr-0 md:mr-20 lg:mr-16">{formatPostDate(date)}</div>
            </div>
            <h3 className="title-article mt-3">{title}</h3>
            <div className="min-h-[32px] flex-1"></div>
            <div className="flex items-center space-x-2">
              <span>{etc.readMoreLabel}</span>
              <motion.div
                initial={{ opacity: 0, x: "-75%" }}
                animate={
                  isResponsiveLG ? (hover ? { opacity: 1, x: 0 } : {}) : { opacity: 1, x: 0 }
                }
                transition={{ ease: "easeIn", duration: 0.3 }}
                className="pt-0.5"
              >
                <IconNewsletterArrow />
              </motion.div>
            </div>
          </div>
        </motion.div>
      </Link>
    </motion.div>
  )
}

export const PostCardNewsletter = ({
  image,
  title,
  placeholder,
  tc,
  formName,
}: {
  image: WPMedia
  title: string
  placeholder: string
  tc: string
  formName: string
}) => {
  const formId = useId()
  const { formMessages } = useGlobalFields()
  const { refEmail, refTC, submit, loading, hasSubmitted, error } = useNewsletterForm(formName)
  const [hover, setHover] = useState(false)
  const [focused, setFocused] = useState(false)
  const animation = useAnimation()

  useCirculartAnimation(hover && !focused, animation, 2, SPEED.FAST)

  // observe focus state on the email field
  // and stop card animation when it is focused
  useEffect(() => {
    const refEmailCopy = refEmail.current!
    const handleFocus = () => setFocused(true)
    const handleBlur = () => setFocused(false)

    refEmailCopy.addEventListener("focus", handleFocus)
    refEmailCopy.addEventListener("blur", handleBlur)

    return () => {
      refEmailCopy.removeEventListener("focus", handleFocus)
      refEmailCopy.removeEventListener("blur", handleBlur)
    }
  }, [refEmail])

  return (
    <motion.div
      className="h-full cursor-pointer"
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <motion.div
        className="rounded-article-card flex h-full cursor-pointer flex-col overflow-hidden rounded-[15px] bg-[#D1D6D9] text-black"
        variants={baseCardVariants}
        animate={animation}
      >
        <div className="aspect-w-16 aspect-h-9">
          <Image src={image.mediaItemUrl} alt={image.altText} layout="fill" objectFit="cover" />
        </div>
        <div className="flex-1 px-10 pt-4 pb-6 lg:px-12">
          <h3 className="title-article" dangerouslySetInnerHTML={{ __html: title }} />

          {/* show form fields if not yet done  */}
          {!hasSubmitted && (
            <>
              <div className="relative mt-4">
                <input
                  ref={refEmail}
                  type="text"
                  className="footer-newsletter-input inverted"
                  id={`${formId}:email`}
                  name={`${formId}:email`}
                  placeholder={placeholder}
                />
                {loading ? (
                  <IconLoading className="absolute-center-y right-1 w-10 fill-black" />
                ) : (
                  <IconNewsletterArrow
                    className="absolute-center-y right-4 cursor-pointer fill-black hover:opacity-80"
                    onClick={submit}
                  />
                )}
              </div>
              <div className="mt-[22px] flex space-x-2">
                <input
                  ref={refTC}
                  type="checkbox"
                  className="form-chk mt-1"
                  name={`${formId}:gdpr`}
                  id={`${formId}:gdpr`}
                  disabled={loading}
                  onChange={e => e.target.checked && refEmail.current?.focus()}
                />
                <label
                  className="select-none text-xs"
                  htmlFor={`${formId}:gdpr`}
                  dangerouslySetInnerHTML={{ __html: tc }}
                />
              </div>
            </>
          )}

          {/* show thank you message if form was submitted successfully */}
          {hasSubmitted && <IconFormCheck className="mt-6 h-32 w-32 lg:h-36 lg:w-36" />}

          {/* show error messages (if any) */}
          {error !== null && (
            <div className="mt-2 ml-5 h-4 text-sm text-red-500">
              {error === FormError.InvalidEmail
                ? formMessages.invalidEmail
                : error === FormError.UncheckedTC
                ? formMessages.uncheckedTc
                : formMessages.genericError}
            </div>
          )}
        </div>
      </motion.div>
    </motion.div>
  )
}

const buildPostSlug = (postType: WPPostTypePlural, slug: string, locale?: string | null) => {
  // determine root of post based on the given postType
  const appLocale = getAppLocale(locale)
  const root =
    postType === "verksamhetsomraden" || postType === "branscher"
      ? confServices.single[postType][appLocale]
      : postType === "medarbetares"
      ? confEmployees[appLocale]
      : confPosts.single[appLocale]

  return `/${root}/${slug}`
}

import type { CSSProperties, ReactNode } from "react"

import Link from "next/link"

interface AppLinkProps {
  className?: string
  style?: CSSProperties
  href: string
  external?: boolean
  replace?: boolean
  prefetch?: boolean
  children: ReactNode
  onClick?: () => void
}

export const AppLink = ({
  className,
  style,
  href,
  external,
  replace,
  prefetch,
  children,
  onClick,
}: AppLinkProps) => {
  if (external)
    return (
      <a
        className={className}
        href={href}
        target="_blank"
        rel="noreferrer"
        data-content={typeof children === "string" ? children : null}
        style={style}
      >
        {children}
      </a>
    )

  return (
    <Link href={href} replace={replace} prefetch={prefetch} legacyBehavior>
      <a
        className={className}
        style={style}
        onClick={onClick}
        data-content={typeof children === "string" ? children : null}
      >
        {children}
      </a>
    </Link>
  )
}

export const addSearchQuery = (query: string): [string, undefined, { shallow: boolean }] | null => {
  const url = new URL(window.location.href)
  const q = url.searchParams.get("q")
  const queries = typeof q === "string" && q.length !== 0 ? decodeURIComponent(q).split(",") : []

  // check for duplicate queries
  const lQueries = queries.map(_query => _query.toLowerCase())
  if (lQueries.includes(query.trim().toLowerCase())) return null

  // concat new query
  const newQueries = encodeURIComponent(queries.concat(query.trim()).join(","))
  const shallow = url.pathname === "/search"

  return [`/search?q=${newQueries}`, undefined, { shallow }]
}

export const removeSearchQuery = (
  query: string
): [string, undefined, { shallow: boolean }] | null => {
  const url = new URL(window.location.href)
  const q = url.searchParams.get("q")
  const queries = typeof q === "string" && q.length !== 0 ? decodeURIComponent(q).split(",") : []

  // check query existence
  const lQueries = queries.map(_query => _query.toLowerCase())
  const index = lQueries.indexOf(query.toLowerCase())
  if (index === -1) return null

  // remove query
  queries.splice(index, 1)
  const newQueries = encodeURIComponent(queries.join(","))
  const path = queries.length >= 1 ? `/search?q=${newQueries}` : "/search"
  const shallow = url.pathname === "/search"

  return [path, undefined, { shallow }]
}

export const popSearchQuery = (): [string, undefined, { shallow: boolean }] | null => {
  const url = new URL(window.location.href)
  const q = url.searchParams.get("q")
  const queries = typeof q === "string" && q.length !== 0 ? decodeURIComponent(q).split(",") : []

  // check queries length
  if (queries.length === 0) return null

  // remove last query
  queries.splice(queries.length - 1, 1)
  const newQueries = encodeURIComponent(queries.join(","))
  const path = queries.length >= 1 ? `/search?q=${newQueries}` : "/search"
  const shallow = url.pathname === "/search"

  return [path, undefined, { shallow }]
}

import CookieBot from "react-cookiebot"

import { useCookieBotEvents } from "@hooks/cookiebot"

const domainGroupId = process.env.NEXT_PUBLIC_COOKIEBOT_DOMAIN_ID as string

export const CookieBanner = () => {
  const showCookieBot = useCookieBotEvents()

  return showCookieBot ? <CookieBot domainGroupId={domainGroupId} /> : null
}

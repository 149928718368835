import { IconProps } from "./props"

export const IconDocument = ({ className }: IconProps) => {
  return (
    <svg className={className} width="14" height="16" viewBox="0 0 14 16">
      <g clipPath="url(#clip0_506_1581)">
        <path d="M7.95 0H0v15.58h13.05V5.01L7.95 0zm.36 1.4l3.38 3.32H8.31V1.4zM.75 14.83V.75h6.81v4.72h4.74v9.36H.75z"></path>
      </g>
      <defs>
        <clipPath id="clip0_506_1581">
          <path fill="#fff" d="M0 0H13.05V15.58H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

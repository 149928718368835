import { motion, Variants } from "framer-motion"

import { IconProps } from "./props"

interface IconFormCheckProps extends IconProps {
  stroke?: string
}

const varCheck: Variants = {
  init: { pathLength: 0 },
  enter: { pathLength: 1, transition: { delay: 0.7, duration: 0.5, ease: "easeInOut" } },
}
const varCircle: Variants = {
  init: { pathLength: 1, pathOffset: 1 },
  enter: { pathLength: 1, pathOffset: 0, transition: { duration: 0.8, ease: "easeInOut" } },
}

export const IconFormCheck = ({ className, stroke = "black" }: IconFormCheckProps) => {
  return (
    <motion.svg
      className={className}
      version="1.1"
      id="Lager_1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 215 191"
    >
      <motion.path
        initial="init"
        animate="enter"
        variants={varCircle}
        d="M184.7,64.7c3.3,9.7,5.1,20,5.1,30.8c0,52.2-42.3,94.5-94.5,94.5S0.8,147.7,0.8,95.5S43.1,1,95.3,1
        c25.5,0,48.6,10.1,65.6,26.4"
        stroke={stroke}
        strokeWidth={2}
      />
      <motion.path
        initial="init"
        animate="enter"
        variants={varCheck}
        transition={{ delay: 0.7, duration: 0.7, ease: "easeInOut" }}
        d="M38,66.5 95.3,123.8 214.2,4.9 "
        stroke={stroke}
        strokeWidth={2}
      />
    </motion.svg>
  )
}

import { useNProgress } from "@tanem/react-nprogress"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

export const ProgressBar = () => {
  const router = useRouter()
  const [isAnimating, setIsAnimating] = useState(false)
  const { animationDuration, isFinished, progress } = useNProgress({ isAnimating })

  // add custom route change animation
  useEffect(() => {
    const handleStart = () => setIsAnimating(true)
    const handleStop = () => setIsAnimating(false)

    router.events.on("routeChangeStart", handleStart)
    router.events.on("routeChangeComplete", handleStop)
    router.events.on("routeChangeError", handleStop)

    return () => {
      router.events.off("routeChangeStart", handleStart)
      router.events.off("routeChangeComplete", handleStop)
      router.events.off("routeChangeError", handleStop)
    }
  }, [router])

  return (
    <div
      className="pointer-events-none"
      style={{ opacity: isFinished ? 0 : 1, transition: `opacity ${animationDuration}ms linear` }}
    >
      <div
        className="fixed top-0 left-0 z-[9999] h-1 w-full bg-white"
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
        }}
      ></div>
    </div>
  )
}

import { motion, useInView } from "framer-motion"
import { ReactNode, useRef } from "react"

interface FadeInSlideUpProps {
  children: ReactNode
  threshold: number
  className?: string
}

export function FadeInSlideUp({ children, threshold, className }: FadeInSlideUpProps) {
  const ref = useRef<HTMLDivElement>(null!)
  const isInView = useInView(ref, { once: true, amount: threshold })

  return (
    <motion.div
      ref={ref}
      initial={{ y: 64, opacity: 0 }}
      animate={isInView ? { y: 0, opacity: 1 } : {}}
      transition={{ ease: "easeOut", duration: 1 }}
      className={className}
    >
      {children}
    </motion.div>
  )
}

import { IconProps } from "./props"

export const IconNewsletterArrow = ({ className, style, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="15"
      viewBox="0 0 20 15"
      style={style}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_497_2806)">
        <path d="M12.8.133a.502.502 0 000 .709l5.347 5.782H.501A.508.508 0 000 7.125a.508.508 0 00.501.501h17.492l-5.22 5.635a.501.501 0 00.709.708l6.216-6.684a.267.267 0 000-.36L13.482.24A.501.501 0 0012.8.133z" />
      </g>
      <defs>
        <clipPath id="clip0_497_2806">
          <path d="M0 0H19.791V14.11H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

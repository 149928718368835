import { useEffect, useState } from "react"
import TagManager from "react-gtm-module"

declare global {
  interface Window {
    Cookiebot: {
      consent: {
        marketing: boolean
        necessary: boolean
        preferences: boolean
        statistics: boolean
        stamp: string
      }
      hasResponse: boolean
    }
  }
}

export function useCookieBotEvents() {
  const [showCookieBot, setShowCookieBot] = useState<boolean>(false)

  useEffect(() => {
    process.env.NEXT_PUBLIC_GTMID && TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTMID })
    setShowCookieBot(document.querySelector("#CookieBot") === null)
  }, [])

  return showCookieBot

  // Block for CookieBot Accepting statistics consent
  // useEffect(() => {
  //   const cookibotAccept = () => {
  //     if (window.Cookiebot.consent.statistics) {
  //       // embed google tag manager tracking code
  //       process.env.NEXT_PUBLIC_GTMID &&
  //         TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTMID })
  //     }
  //   }

  //   window.addEventListener("CookiebotOnAccept", cookibotAccept)

  //   return () => window.removeEventListener("CookiebotOnAccept", cookibotAccept)
  // }, [])
}

import { WPPostCategoryLabel } from "@models/posts"

export const formatPostDate = (dateISO: string): string => {
  const date = new Date(dateISO)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  return `${year}.${month}.${day}`
}

export const formatPostCategory = (
  categories: string[] | null | undefined,
  postCategoryLabel: WPPostCategoryLabel
): string => {
  const { default: defaultLabel, event, news, recentWork } = postCategoryLabel
  if (!categories) return defaultLabel

  if (categories.length > 1)
    console.warn(`Got multiple categories, will only use the first one. ${categories}`)

  const [category] = categories

  if (category === "event" || category === "event-en") return event
  if (category === "nyheter" || category === "news") return news
  if (category === "uppdrag" || category === "recent-work") return recentWork

  return defaultLabel
}

import { motion, useAnimation, Variants } from "framer-motion"
import Image from "next/legacy/image"
import { useEffect, useState } from "react"

import bg1svg from "@assets/gradients/cq_gradienter-01.svg"
import bg2svg from "@assets/gradients/cq_gradienter-02.svg"
import bg3svg from "@assets/gradients/cq_gradienter-03.svg"
import bg4svg from "@assets/gradients/cq_gradienter-04.svg"

const variants: Variants = {
  init: { opacity: 1 },
  fade: { opacity: 0, transition: { duration: 6 } },
}
const bgs = [bg1svg, bg2svg, bg3svg, bg4svg]

export const GradientBG = ({
  enableAnimation = process.env.NEXT_PUBLIC_DISABLE_BG !== "true",
}: {
  enableAnimation?: boolean
}) => {
  const [index, setIndex] = useState(0)
  const [shouldStartAnim, setShouldStartAnim] = useState(false)
  const animation = useAnimation()

  // delay bg animation 3s after the browser has been idle
  useEffect(() => {
    const handler = () => setTimeout(() => setShouldStartAnim(true), 3000)
    const id = setTimeout(handler)
    return () => clearTimeout(id)
  }, [])

  // start animation when it is enabled and it is ready
  useEffect(() => {
    if (shouldStartAnim && enableAnimation) {
      animation.start("fade").then(() => setIndex((index + 1) % bgs.length))
      return () => animation.stop()
    }
  }, [animation, shouldStartAnim, enableAnimation, index])

  return (
    <div className="fixed inset-0 z-[-1] bg-black">
      <motion.div
        key={index % bgs.length}
        className="absolute inset-0 z-20"
        variants={variants}
        initial="init"
        animate={animation}
      >
        <Image
          src={bgs[index % bgs.length]}
          alt="bg"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          priority
        />
      </motion.div>
      <motion.div
        key={(index + 1) % bgs.length}
        className="absolute inset-0 z-10"
        variants={variants}
        initial="init"
      >
        {shouldStartAnim && (
          <Image
            src={bgs[(index + 1) % bgs.length]}
            alt="bg"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority
          />
        )}
      </motion.div>
    </div>
  )
}

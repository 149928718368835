import { AnimationControls } from "framer-motion"
import { useEffect } from "react"

export enum SPEED {
  NORMAL = 360,
  FAST = 300,
  // FAST = 180,
  FASTER = 135,
}

export const useCirculartAnimation = (
  animate: boolean,
  animation: AnimationControls,
  radius: number,
  s: SPEED = SPEED.NORMAL,
  a: number = 0
) =>
  useEffect(() => {
    let interval: NodeJS.Timer,
      angle = a

    if (animate) {
      interval = setInterval(() => {
        // angle = (angle + Math.PI / 135) % (Math.PI * 2)
        angle = (angle + Math.PI / s) % (Math.PI * 2)

        const px = radius * Math.cos(angle)
        const py = radius * Math.sin(angle)

        animation.set({ x: px, y: py, transition: { ease: "easeInOut" } })
      }, 3)
    } else {
      animation.stop()
      animation.set({ rotate: 0, x: 0, y: 0, opacity: 1 })
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [animate, animation, a, radius, s])

import { IconProps } from "./props"

export const IconSearch = ({ className, style, onClick }: IconProps) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      style={style}
      onClick={onClick}
    >
      <g strokeMiterlimit="10" clipPath="url(#clip0_497_1126)">
        <path
          d="M7.52 14.54A7.02 7.02 0 107.52.5a7.02 7.02 0 000 14.04zM12.48 12.48l5.22 5.22"
          fill="none"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_497_1126">
          <path d="M0 0H18.06V18.06H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

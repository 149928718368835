import { AllNextLocales, AppLocale, WpLocale } from "@models/common"

export const getAppLocale = (locale?: string | null): AppLocale => {
  if (locale === "en" || locale === "EN") return "en"
  return "sv"
}

export const getWpLocale = (locale?: string | null): WpLocale => {
  if (locale === "en") return "EN"
  return "SV"
}

export const isAppLocale = (object: any): object is AppLocale =>
  typeof object === "string" && AllNextLocales.includes(object as any)

export const parsePageIds = (id: string): Map<string, string> => {
  const entries = id.split(";").map(item => item.split(":")) as [string, string][]
  return new Map(entries)
}

export const getPageIdFromLocale = (id: string, locale: AppLocale): string => {
  const idMap = parsePageIds(id)
  if (idMap.has(locale)) return idMap.get(locale)!
  throw new Error(`ID "${id}" does not contain page ID for locale=${locale}`)
}
